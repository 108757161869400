@import "~@/assets/scss/global.scss";




































.div-help-right {
  position: absolute;
}

.cashier {
  left: 10px !important;
  bottom: 4px !important;
}

.qq {
  right: 70px !important;
  top: 12px !important;
}

.preorder {
  right: 10px !important;
  top: 5px !important;
}

.allocation {
  top: 5px !important;
  left: 500px !important;
}

.product {
  top: 5px !important;
  right: 260px !important;
}

.trade_data {
  top: 5px !important;
  right: 300px !important;
}

.index {
  position:relative !important;
  ::v-deep {
    .el-button--text {
      color: #ffffff !important;
    }

  }
}
