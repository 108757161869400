@import "~@/assets/scss/global.scss";























































.button-group {
  .button {
    display: inline-block;
    margin-right: 5px;
    padding: 0px 5px;
    font-size: 14px;
    border: 1px solid rgba(255, 255, 255, 0);
    cursor: pointer;

    .el-icon-arrow-down {
      margin-left: 4px;
    }

    .button-icon {
      font-size: 16px;
      vertical-align: middle;
    }

    .button-text {
      vertical-align: middle;
    }

    &:hover {
      border: 1px solid #ecf5ff;
      border-radius: 2px;
      background: #ecf5ff;
      vertical-align: middle;
    }
  }

  .divider {
    margin: 0 10px 0 5px;
  }
}
