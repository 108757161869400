@import "~@/assets/scss/global.scss";





















































::v-deep {
  .el-input__suffix-inner {
    i {
      line-height: 32px;
      cursor: pointer;
    }
  }
}
