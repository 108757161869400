@import "~@/assets/scss/global.scss";
















































































.el-form {
  display: inline-block;
  vertical-align: top;

  .el-form-item {
    margin-bottom: 0 !important;
  }
}

.collapse-block {
  display: none;
}
