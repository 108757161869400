@import "~@/assets/scss/global.scss";































































































































.layout-wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;

  &>main,
  &>footer {
    border-top: 0 !important;
  }

  .layout-middle {
    position: relative;

    .layout-menu {
      height: 100%;
      //position: absolute;
      //z-index: 999;
    }

    &>aside {
      padding: 0;
    }

    .sub-menu {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      border: 0;

      &:not(.el-menu--collapse) {
        width: 130px;
      }
    }

    #layout-main {
      background-color: rgba(233, 237, 241, 1) !important;
      border-left: 0 !important;
      padding: 10px;

      &.show-menu {
        // padding-left: 74px;
      }

      #main-wrapper {

        &>main,
        &>footer {
          border-top: 0 !important;
        }

        #main-container {
          padding: 0;
          position: relative;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }
    }
  }
}
