@import "~@/assets/scss/global.scss";



















































































.el-badge {
    width: 100%;
}

.el-menu-item {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 0px !important;
    min-width: auto;

    &.is-active {
        background: #0075e3 !important;
    }

    ::v-deep {
        .el-tooltip {
            padding: 0 !important;
        }
    }
}

::v-deep {
    .el-badge__content {
        top: 15px;
        right: 30px;
        border-color: #f56c6c;
        font-size: small;
        font-weight: normal;
        padding: 0 2px;

        &.is-dot {
            right: 15px;
            padding: 0;
        }
    }

    .el-submenu__title {
        color: white;
        font-size: 18px;
        font-weight: 600;
        height: 50px;
        line-height: 50px;
        text-align: center;
        padding: 0px !important;

        .el-submenu__icon-arrow {
            font-size: 18px;
            font-weight: 600;
            right: 0;
        }
    }

    .el-submenu__title i {
        color: white;
    }

    .el-submenu__title:hover,
    .el-menu-item:hover {
        background: #0075e3 !important;
    }
}
