@import "~@/assets/scss/global.scss";@charset "utf-8";

$base: '.base';

@mixin scrollbar {
  max-height: 88vh;
  margin-bottom: 0.5vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
}

@mixin base-scrollbar {
  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    background-clip: padding-box;
    border: 3px solid #f1f1f1;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
}

* {
  transition: $base-transition;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: $base-font-size-default;
    color: #2c3e50;
    background: #f6f8f9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    #app {
      height: 100%;
      width: 100%;
      padding: 0;
      margin: 0;
    }

    @include base-scrollbar;

    div {
      @include base-scrollbar;
    }

    svg,
    i {
      &:hover {
        opacity: 0.8;
      }
    }

    .el-table th.gutter {
      display: table-cell !important;
    }

    .el-tag+.el-tag {
      margin-left: 10px;
    }

    .editor-toolbar {

      .no-mobile,
      .fa-question-circle {
        display: none;
      }
    }

    .el-divider--horizontal {
      margin: 0;

      .el-divider__text {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .el-image-viewer {
      &__close {
        .el-icon-circle-close {
          color: $base-color-white;
        }
      }
    }

    .layout-wrapper {
      .main-container {
        @include base-scrollbar;

        >[class*='-container'] {
          * {
            transition: none;
          }

          padding: $base-padding;
          background: $base-color-white;
        }
      }
    }

    .el-table {
      width: 100%;

      .el-table__header-wrapper {
        .el-table__header {
          th {
            background-color: #f3f7f9 !important;
          }
        }
      }
      .el-table__fixed-header-wrapper {
        .el-table__header {
          th {
            background-color: #eef1f6 !important;
          }
        }
      }

      .el-table__body-wrapper {
        @include base-scrollbar;
      }
      
      th.gutter,
      colgroup.gutter {
        width: 13px !important; //此处的宽度值，对应你自定义滚动条的宽度即可
        display: block !important;
      }

      td,
      th {
        position: relative;
        box-sizing: border-box;
        padding: 7.5px 0;

        .cell {
          font-size: $base-font-size-default;
          font-weight: normal;
          color: #606266;

          .el-image {
            width: 50px;
            height: 50px;
            border-radius: $base-border-radius;
          }
        }
      }
    }

    .el-pagination {
      padding: 2px 5px;
      margin: 8px 0 0 0;
      font-weight: normal;
      color: $base-color-black;
    }

    .el-menu-item,
    .el-submenu {
      &.is-active {
        background: #0075e3;
      }
    }

    .el-menu.el-menu--popup.el-menu--popup-right-start {
      @include scrollbar;
    }

    .el-menu.el-menu--popup.el-menu--popup-bottom-start {
      @include scrollbar;
    }

    .el-submenu__title i {
      color: $base-color-black;
    }

    .el-dialog,
    .el-message-box {
      max-height: 550px;
      position: relative;
      border-radius: 4px;

      &__header {
        height: 26px;
        padding: 10px;
      }

      &__content {
        max-height: 300px;
        overflow-y: auto;
      }

      &__body {
        border-top: 1px solid $base-border-color;
        padding: 18px;
        max-height: 400px;
        overflow: auto;

        .el-form {
          padding-right: 30px;
        }
      }

      &__footer {
        height: 55px;
        padding: $base-padding;
        text-align: right;
        border-top: 1px solid $base-border-color;
        padding: 10px 15px;

        .el-button {
          padding: 10px 40px;
        }
      }

      &__btns {
        height: 20px;
        padding: $base-padding;
        text-align: right;
        border-top: 1px solid $base-border-color;
        padding: 10px 15px;

        .el-button {
          padding: 10px 15px;
          height: 30px;
        }
      }
    }

    .el-card {
      margin-bottom: 15px;

      &__body {
        padding: $base-padding;
      }
    }

    .select-tree-popper {
      .el-scrollbar {
        .el-scrollbar__view {
          .el-select-dropdown__item {
            height: auto;
            max-height: 274px;
            padding: 0;
            overflow-y: auto;
            line-height: 26px;
          }
        }
      }
    }

    .tree-wrapper {
      border: 1px solid #0075e3;

      .tree-title {
        height: 30px;
        background: #0075e3;
        font-size: 14px;
        line-height: 30px;
        color: white;

        i {
          font-size: 19px;
          margin: 0 5px;
          vertical-align: middle;
        }
      }
    }

    .el-form {
      .el-form-item {
        margin-bottom: 8px !important;

        &.is-error {
          margin-bottom: 15px !important;
        }

        .el-date-editor {
          &.el-input--suffix {
            ::v-deep {
              .el-input__inner {
                padding-right: 0;
              }

              .el-input__suffix {
                display: none;
              }
            }
          }
        }
      }
    }

    .el-button {
      vertical-align: middle;
      height: auto;
    }

    .el-input {
      .el-input__prefix {
        left: 0 !important;
      }
    }

    .el-container {
      background-color: #fff;
      height: 100%;
      width: 100%;
      @include base-scrollbar;

      &>aside,
      &>main,
      &>section {
        box-sizing: border-box;
        background-color: inherit;
        padding: 5px;

        @include base-scrollbar;

        &>section {
          padding: 0;
        }
      }

      &.is-vertical {

        &>header,
        &>main,
        &>footer,
        &>section {
          padding: 0;
        }

        header+main,
        main+footer {
          border-top: 1px solid #DCDFE6;
        }
      }

      aside+section,
      aside+main,
      section+aside,
      main+aside {
        border-left: 1px solid #DCDFE6;
      }
    }
  }
}

img {
  object-fit: cover;
}

a {
  color: $base-color-blue;
  text-decoration: none;
  cursor: pointer;
}

svg {
  transition: none;

  * {
    transition: none;
  }
}