@import "~@/assets/scss/global.scss";































































































































































































#layout-header {
  width: 100%;
  background: $base-color-blue;
  height: $base-header-height;
  position: relative;
  color: rgba(255, 255, 255, 1);

  .el-aside,
  .el-main {
    border-left: 0;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }

  .el-menu {
    border-bottom: 0 !important;
  }

  #header-left {
    justify-content: right !important;

    img {
      // width: 172px;
      height: 40px;
      object-fit: contain;
    }
  }

  #header-nav {
    line-height: $base-header-height;
    // margin-left: 20px;
    align-items: flex-end;

    .el-menu {
      display: contents;
      overflow-x: auto;
    }

    .el-menu-item {
      font-size: 20px;
      font-weight: 600;
      margin: 0 10px;
      border-bottom: 4px solid;
      background-color: #0075e3 !important;
    }
  }

  #header-right {
    line-height: $base-header-height;
    justify-content: right !important;
    align-items: center;

    .el-submenu {

      ::v-deep {
        .el-submenu__title {
          height: 100%;
          font-weight: 400;
          border-bottom: 0;
          background-color: #0075e3 !important;
        }

        i {
          color: #fff;
          font-size: 15px;
        }
      }
    }
  }

  #header-bell {
    margin-right: 10px;

    ::v-deep {
      .el-button--text {
        color: #ffffff !important;
      }
    }
  }
}

.el-menu--popup {
  .el-menu-item {
    line-height: 46px !important;
    height: 46px;
    display: flex;
    justify-content: center;

    i {
      color: #fff;
      margin-right: 5px;
      font-size: 15px;
    }
  }
}
