@import "~@/assets/scss/global.scss";





































.tree-wrapper {
  ::v-deep {
    .el-tree {
      height: 100%;
      overflow: auto;
    }
  }
}
