@import "~@/assets/scss/global.scss";









































































::v-deep {
    .el-dialog__body {
        height: 600px;
        max-height: 600px;
        background-color: white;
    }

    .el-form-item {
        height: 50px;
    }

    .el-form-item__label {
        color: #1B1B1B;
        font-size: 16px;
        font-weight: 550;
    }
}

.renewal-option {
    text-align: center;
    display: inline-block;
    width: 130px;
    padding: 5px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 0.3em;
    border: 1px solid #D6D6D6;
    box-shadow: 2px 2px 2px #D6D6D6;

    &:hover {
        box-shadow: 5px 5px 5px #D6D6D6;
    }

    &.active {
        color: #FF8737;
        border: 1px solid #FF8737;
    }
}

.renewal-amount {
    font-size: 35px;
    font-weight: bold;
    color: #D9001B;

    &.discount {
        text-decoration: line-through;
        color: #D6D6D6;
    }
}

.renewal-date {
    color: #1B1B1B;
    font-size: 16px;
    font-weight: bold;
}
