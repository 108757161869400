@import "~@/assets/scss/global.scss";




















































.main-content {
  height: 100%;

  >div {
    height: 100%;
  }
}
