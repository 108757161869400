@import "~@/assets/scss/global.scss";



































.layout-footer {
  width: 100%;
  height: $base-footer-height;
  line-height: $base-footer-height;
  background: white;
  text-align: center;
  font-size: 13px;
  font-weight: 400;

  .el-divider {
    background-color: #2c3e50;
  }

  a {
    color: #2c3e50;

    img {
      vertical-align: middle;
      height: 20px;
      width: 20px;
    }
  }
}

.el-main{
  overflow: hidden;
}
